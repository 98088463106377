/* eslint-disable no-undef */
const keys = {
  tokenData: 'am-token-data',
  logProvider: 'log-provider',
  isTokenLoading: 'is-refresh-token-updating',
  theme: 'theme',
};

export const storeAccessTokenCookie = (accessToken) => {
  document.cookie = `access_token=${accessToken}`;
};

export const removeAccessTokenCookie = () => {
  document.cookie = 'access_token=';
};

export const storeTokenData = (tokenData) => {
  localStorage.setItem(keys.tokenData, JSON.stringify(tokenData));
  storeAccessTokenCookie(tokenData.access_token);
};

export const storeLoginProvider = (provider = 'default') => {
  localStorage.setItem(keys.logProvider, JSON.stringify(provider));
};

export const getLoginProvider = () => {
  try {
    return JSON.parse(localStorage.getItem(keys.logProvider));
  } catch (e) {
    return null;
  }
};

export const getTokenData = () => {
  try {
    return JSON.parse(localStorage.getItem(keys.tokenData));
  } catch (e) {
    return null;
  }
};

export const removeTokenData = () => {
  localStorage.removeItem(keys.tokenData);
  localStorage.removeItem(keys.logProvider);
  removeAccessTokenCookie();
};

export const storeTokenLoading = (isLoading) => {
  localStorage.setItem(keys.isTokenLoading, JSON.stringify(isLoading));
};

export const getTokenLoading = () => {
  try {
    return JSON.parse(localStorage.getItem(keys.isTokenLoading));
  } catch (e) {
    return false;
  }
};
