import { Box, Loader, Stack } from '@mantine/core';

export default function PageLoaderFallback() {
  return (
    <Box mt={50} fw={500} w="max-content" h="100%" mx="auto" fz={25}>
      <Stack align="center">
        Page is loading, please wait...
        <Loader type="dots" size={50} />
      </Stack>
    </Box>
  );
}
