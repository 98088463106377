import _ from 'lodash';

export const queryKeys = {
  employees: (isFullList, page, search, status, params) => [
    'employees',
    isFullList,
    page,
    search,
    status,
    params,
  ],
  employee: (id) => ['employees', _.toString(id)],
  employeeProfile: (id) => ['employeesProfile', _.toString(id)],
  requests: (params, isFullList) => ['requests', ...params, isFullList],
  request: (id) => ['request', id],
  jobs: (page, search) => ['jobs', page, search],
  job: (id, params) => ['job', _.toString(id), params],
  candidates: (page, search) => ['candidates', page, search],
  candidate: (id) => ['candidate', _.toString(id)],
  talentGroups: () => ['talent_groups'],
  talentGroup: (id) => ['talent_group', _.toString(id)],
  user: () => ['am_user'],
  employeeSchema: () => ['employeeSchema'],
  employeeProfileSchema: () => ['employeeProfileSchema'],
  notes: (contentId, contentType) => ['notes', contentId, contentType],
  files: (contentId, contentType, parentId, userId, canManage, ...params) => [
    'files',
    contentId,
    contentType,
    parentId,
    userId,
    canManage,
    params,
  ],
  notifications: (status, payload) => ['push_notifications', status, payload],
  trainings: () => ['trainings'],
  trainingsCategories: (payload) => ['trainingsCategories', payload],
  timeOffPolicies: (isFullList, employeeId = '') => [
    'timeOffPolicies',
    employeeId,
    isFullList,
  ],
  timeOffCategories: (isFullList) => ['timeOffCategories', isFullList],
  timeOffRecords: (isFullList, ...params) => [
    'timeOffRecords',
    isFullList,
    params,
  ],
  userPolicy: (userId) => ['userPolicy', userId],
  policy: (id) => ['policy', id],
  employeePolicyList: (userId) => ['employeePolicy', userId],
  performanceCycles: () => ['performanceCycles'],
  performanceCycle: (id) => ['performanceCycle', _.toString(id)],
  surveys: (params) => ['surveys', ...params],
  survey: (id) => ['survey', id],
  users: (page, search) => ['users', page, search],
  userTrainings: (id) => ['userTrainings', id],
  userRecords: (id, ...params) => ['userRecords', id, params],
  jobTitles: () => ['jobTitles'],
  departments: () => ['departments'],
  locations: () => ['locations'],
  divisions: () => ['divisions'],
  jobInformationList: (employeeId) => ['jobInformationList', employeeId],
  tasksList: (params) => ['tasksList', params],
  tasks: (params) => ['tasks', params],
  employeeBoardingList: (id, params) => ['employeeBoardingList', id, params],
  candidateSource: () => ['candidateSource'],
  candidateStatuses: () => ['candidateStatuses'],
  emailTemplates: () => ['emailTemplates'],
  offerTemplates: () => ['offerTemplates'],
  candidateQuestions: (jobId, candidateId) => [
    'candidateQuestions',
    jobId,
    candidateId,
  ],
  candidateOffer: (hash) => ['candidateOffer', hash],
  templateVariables: () => ['templateVariables'],
  manageRequest: (id) => ['manageRequest', _.toString(id)],
  employeeRequest: (id) => ['employeeRequest', _.toString(id)],
  dashboardInfo: () => ['dashboardInfo'],
  lookups: ({ models = [], ...rest }) => ['lookups', models, rest],
  headCountReportStats: (params) => ['headCountReportStats', params],
  headCountList: (params) => ['headCountList', params],
  isValidResetPasswordToken: (token) => ['isValidResetPasswordToken', token],
  employeeStatuses: () => ['employeeStatuses'],
  employeeStatusRecords: (employeeId) => [
    'employeeStatusRecords',
    _.toString(employeeId),
  ],
  reasons: (type) => ['reasons', type],
  paySchedules: () => ['paySchedules'],
  employeeCompensationRecords: (id) => [
    'employeeCompensationRecords',
    _.toString(id),
  ],
  employeeOrgTree: () => ['employeeOrgTree'],
  employeeBonusRecords: (id) => ['employeeBonusRecords', _.toString(id)],
  employeeAssessmentSurveys: (id, payload) => [
    'employeeAssessmentSurveys',
    payload,
    _.toString(id),
  ],
  totalEmployeesByJobInfoFilter: (filter) => [
    'totalEmployeesByJobInfoFilter',
    filter,
  ],
  assetCategories: () => ['assetCategories'],
  employeeAssets: (employeeId) => ['employeeAssets', _.toString(employeeId)],
  employeeSubordinates: (employeeId) => [
    'employeeSubordinates',
    _.toString(employeeId),
  ],
  oauthLogin: (provider) => ['oauthLogin', provider],
  userTokenProvider: (provider) => ['userTokenProvider', provider],
  companyActiveIntegrations: (companyId) => [
    'companyActiveIntegrations',
    companyId,
  ],
  integrations: () => ['integrations'],
  fileBlob: (fileId) => ['fileBlob', fileId],
  buildVersion: () => ['buildVersion'],
  allRoles: (payload) => ['allRoles', payload],
  compensationGroups: () => ['compensationGroups'],
  compensationBands: () => ['compensationBands'],
  calendarData: (params) => ['calendarData', params],
  eventTypes: () => ['eventTypes'],
  timeOffBalance: (payload) => ['timeOffBalance', payload],
  holidays: (payload) => ['holidays', payload],
  widgets: () => ['widgets'],
  employeeHolidays: (payload) => ['employeeHolidays', payload],
  candidatesOffers: (payload) => ['candidatesOffers', payload],
  companyLinksCategories: () => ['companyLinksCategories'],
  companyLinks: () => ['companyLinks'],
  announcements: (payload) => ['announcements', payload],
  announcement: (announcementId) => ['announcement', announcementId],
  jobOpenings: (params) => ['jobOpenings', params],
  jobOpening: (id) => ['jobOpening', id],
  jobOpeningWhiteList: () => ['jobOpeningWhiteList'],
  requestsCount: (payload) => ['requestsCount', payload],
};
