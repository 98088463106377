import { useUnauthorizedInterceptor } from 'api/api';
import {
  useCheckIsValidUserProviderToken,
  useCurrentUserMutation,
  useLogout,
} from 'api/hooks';
import i18next from 'i18next';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getLoginProvider,
  getTokenData,
  removeTokenData,
  storeAccessTokenCookie,
  storeLoginProvider,
  storeTokenData,
  storeTokenLoading,
} from 'storage';
import { useHistory, useStorePreviousUrlBeforeLogin } from 'utils/hooks';
import echo from 'ws/echo';
import AuthContext from './AuthContext';

export default function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(getTokenData() != null);
  const { setNotifications } = useNotifications();
  const { prevUrl, setPrevUrl } = useStorePreviousUrlBeforeLogin(isLoggedIn);
  const history = useHistory();

  const clearData = useCallback(() => {
    removeTokenData();
    setUser();
    setIsLoggedIn(false);
    storeTokenLoading(false);
  }, []);

  const setTokenData = useCallback(({ provider, ...tokenData }) => {
    storeTokenData(tokenData);
    storeLoginProvider(provider);
    setIsLoggedIn(getTokenData() != null);
  }, []);

  useUnauthorizedInterceptor(clearData, storeTokenData);

  const { logout: apiLogout } = useLogout({
    onSuccess: () => {
      clearData();
      setNotifications(['You are successfully logged out']);
      setPrevUrl('/');
    },
    onError: clearData,
  });
  const { getApiUser, isLoading } = useCurrentUserMutation({
    onSuccess: (response) => {
      setUser(response?.data);
    },
    onError: clearData,
  });

  const initializeAccessTokenCookie = useCallback(() => {
    const token = getTokenData()?.access_token;

    if (token) {
      storeAccessTokenCookie(token);
    }
  }, []);

  useEffect(() => {
    if (!user && isLoggedIn) {
      getApiUser();
      initializeAccessTokenCookie();
    }
  }, [user, getApiUser, isLoggedIn, initializeAccessTokenCookie]);

  useEffect(() => {
    echo.boot(getTokenData()?.access_token);

    if (user?.lang?.code && isLoggedIn) {
      i18next.changeLanguage(user.lang.code);
    }
  }, [user, isLoggedIn]);

  // prevent infinity loading, after page reload
  useEffect(() => {
    storeTokenLoading(false);
  }, []);

  const logout = useCallback(async () => {
    apiLogout();
  }, [apiLogout]);

  const canCheckProviderToken = !!(
    user &&
    isLoggedIn &&
    getLoginProvider() &&
    getLoginProvider() !== 'default'
  );

  useCheckIsValidUserProviderToken(getLoginProvider(), {
    // 600 - 10min
    refetchInterval: 600 * 1000,
    enabled: canCheckProviderToken,
    onError: clearData,
  });

  const auth = useMemo(
    () => ({
      user,
      setUser,
      isLoggedIn,
      setIsLoggedIn,
      setTokenData,
      logout,
      // display loader only first time
      isUserLoading: isLoading && _.isEmpty(user),
      refetchUser: getApiUser,
      prevUrl,
      setPrevUrl,
      clearData,
      history,
    }),
    [
      user,
      setUser,
      isLoggedIn,
      setIsLoggedIn,
      setTokenData,
      logout,
      isLoading,
      getApiUser,
      prevUrl,
      setPrevUrl,
      clearData,
      history,
    ],
  );

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
